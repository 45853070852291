import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { mergeDeepRight } from 'ramda'
import { onError } from '@apollo/link-error'
import { isSSR } from '@/const'
import { AuthAccessToken } from '@/auth/token'
import fragmentMatcher from './fragment-matcher'
import * as Sentry from '@sentry/nextjs'

const {possibleTypes} = fragmentMatcher

function terminatingLink() {
  if (isSSR) {
    return new HttpLink({
      uri: `${process.env.GRAPHQL_API_HOST}/api/student/graphql`,
      headers: {
        'X-Pages-Debug': 'query from client apollo',
      }
    })
  }

  const errorLogLink = onError(({operation, response, graphQLErrors, networkError}) => {

    if (operation.operationName === 'NotificationsCountQ') {
      // Ignore this errors
      return
    }

    Sentry.withScope(scope => {
      scope.setContext('Graphql operation', {
        name: operation.operationName,
        variables: operation.variables,
        resultData: response?.data,
        resultErrors: response?.errors
      })
      scope.setLevel(Sentry.Severity.Error)
      if (networkError) {
        Sentry.captureException(networkError)
      }
      graphQLErrors?.forEach(err => {
        Sentry.captureEvent({
          message: `${err.name}: ${err.message}`,
          contexts: {
            'GraphQL Error': {
              stack: err.stack,
              extensions: JSON.stringify(err.extensions, null, 4)
            }
          }
        })
      })
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any

  const authLink = new ApolloLink((operation, forward) => {
    const token = AuthAccessToken.read()
    if (token) {
      operation.setContext({
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
    }
    return forward(operation)
  })

  // @ts-ignore Outdated typings in apollo-upload-client
  const uploadLink: ApolloLink = createUploadLink({
    uri: `${process.env.NEXT_PUBLIC_GRAPHQL_API_HOST}/api/student/graphql`,
  })

  return from([
    errorLogLink,
    authLink,
    uploadLink
  ])
}

export function createApolloClient() {

  return new ApolloClient({
    ssrMode: isSSR,
    link: terminatingLink(),
    cache: new InMemoryCache({possibleTypes}),
  });

}

let apolloClientSingleton: ReturnType<typeof createApolloClient> | null = null

function rehydrate(apollo: ApolloClient<NormalizedCacheObject>, hydrationState: any = null) {
  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (hydrationState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apollo.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    apollo.cache.restore(mergeDeepRight(existingCache, hydrationState));
  }
  return apollo
}

export function useApolloClient(hydrationState: any = null) {

  if (apolloClientSingleton) {
    return rehydrate(apolloClientSingleton, hydrationState)
  }

  const apolloClient = rehydrate(
    createApolloClient(),
    hydrationState
  );

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClient;

  // Create the Apollo Client once in the client
  apolloClientSingleton = apolloClient
  return apolloClient;
}
