import React from 'react'
import {useCurrentSchool} from '@/auth/AuthContext'
import xw from 'xwind'
import {useSSRCurrentURL} from '@/pages.impl/SSRCurrentURL'
import {isProduction} from '@/const'

const SchoolCustomHtml: React.FC = () => {
  const school = useCurrentSchool()
  const url = useSSRCurrentURL()
  const baseUrl = (isProduction) ? school.baseUrl : 'https://localhost:3000'
  const isEditor = url.includes(`${baseUrl}/_editor`)
  const html = school.customHtml || ''

  const createMarkup = () => {
    return { __html: html };
  }

  return (isEditor) ? <></> : <div css={xw`prose`} dangerouslySetInnerHTML={createMarkup()} />
}

export default SchoolCustomHtml
