import React from 'react'
import { Global } from '@emotion/react'
import xw from 'xwind'

export const GlobalStyles: React.FC = () => (
  <Global
    styles={[
      //tailwind base styles + keyframes + ring and shadow classes variables  ... to global styles
      xw`XWIND_BASE XWIND_GLOBAL`
    ]}
  />
)
