/* eslint-disable */
// THIS IS AUTOGENERATED FILE


      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ICourse": [
      "AvailableCourse",
      "Course"
    ],
    "LectureBlock": [
      "LectureBlockText",
      "LectureBlockImage",
      "LectureBlockEmbedded",
      "LectureBlockHTML",
      "LectureBlockMarkdown",
      "LectureBlockFile",
      "LectureBlockQuiz",
      "LectureBlockUpsell",
      "LectureBlockButton",
      "LectureBlockComments",
      "LectureBlockWebinar",
      "LectureBlockAssignment"
    ],
    "Attachment": [
      "FileAttachment",
      "ImageAttachment"
    ],
    "Notification": [
      "NotificationAssignmentReviewed",
      "NotificationGroupStudyStarted",
      "NotificationCommentReviewed"
    ],
    "SchoolMapItem": [
      "SchoolMapItemFolder",
      "SchoolMapItemCourse"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ICourse": [
      "AvailableCourse",
      "Course"
    ],
    "LectureBlock": [
      "LectureBlockText",
      "LectureBlockImage",
      "LectureBlockEmbedded",
      "LectureBlockHTML",
      "LectureBlockMarkdown",
      "LectureBlockFile",
      "LectureBlockQuiz",
      "LectureBlockUpsell",
      "LectureBlockButton",
      "LectureBlockComments",
      "LectureBlockWebinar",
      "LectureBlockAssignment"
    ],
    "Attachment": [
      "FileAttachment",
      "ImageAttachment"
    ],
    "Notification": [
      "NotificationAssignmentReviewed",
      "NotificationGroupStudyStarted",
      "NotificationCommentReviewed"
    ],
    "SchoolMapItem": [
      "SchoolMapItemFolder",
      "SchoolMapItemCourse"
    ]
  }
};
      export default result;
    