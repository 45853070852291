import * as Sentry from '@sentry/nextjs'
import React from 'react';
import { isProduction } from '@/const'

class ErrorBoundary extends React.Component {

  componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  }

  render() {
    return this.props.children;
  }

}

const Impl = isProduction ? ErrorBoundary : React.Fragment

export default Impl
