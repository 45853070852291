import React from 'react'
import '../styles/global.css'
import type { AppProps } from 'next/app'
import { useApolloClient } from '@/graphql/client'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import ruRu from 'antd/lib/locale/ru_RU'
import AuthContext from '@/auth/AuthContext'
import { GlobalStyles } from '@/styles/GlobalStyles'
import SSRCurrentURL from '@/pages.impl/SSRCurrentURL'
import SchoolCustomHtml from '@/components/SchoolCustomHtml'
import { TrackingContext, TrackPageView } from '@/TrackingContext'
import Head from 'next/head'
import 'moment/locale/ru'
import ErrorBoundary from '@/pages.impl/ErrorBoundary'
import { IntlProvider } from 'react-intl'

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const apolloClient = useApolloClient(pageProps.hydrationState)
  return <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={ruRu}>
        <GlobalStyles/>
        <TrackingContext>
          <AuthContext>
            <SSRCurrentURL url={pageProps.url ?? 'https://glokurs.ru'}>
              <IntlProvider locale="ru-RU">
                <Head>
                  <meta name="apple-itunes-app" content="app-id=1505092967"/>
                  <link rel='manifest' href='/manifest.json'/>
                </Head>
                <Component {...pageProps} />
              </IntlProvider>
            </SSRCurrentURL>
            <SchoolCustomHtml/>
            <TrackPageView/>
          </AuthContext>
        </TrackingContext>
      </ConfigProvider>
    </ApolloProvider>
  </ErrorBoundary>
}

export default MyApp
