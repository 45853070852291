// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  ExtraErrorData,
  Dedupe
} from '@sentry/integrations'
import {
  Integrations
} from "@sentry/tracing"

const isProduction = process.env.NODE_ENV === 'production'
const SENTRY_DSN = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE ?? process.env.NEXT_PUBLIC_SENTRY_RELEASE;


let environment = 'production'
if (!isProduction) {
  if (typeof window !== "undefined" && window.location.hostname.includes('glokurs.com')) {
    environment = 'staging'
  } else {
    environment = 'development'
  }
}

let tracingOrigins = isProduction && typeof window !== "undefined" ? [window.location.hostname] : []

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: isProduction,
  integrations: (defaults) => defaults.concat([
    new ExtraErrorData(),
    new Dedupe(),
    new Integrations.BrowserTracing({
      tracingOrigins
    })
  ]),
  ...(SENTRY_RELEASE ? {release: SENTRY_RELEASE} : {}),
  maxBreadcrumbs: 30,
  attachStacktrace: true,
  normalizeDepth: 5,
  environment: environment,
  autoSessionTracking: true,
  sampleRate: 1.0,        // Send 100% errors
  tracesSampleRate: 0.50, // Send 50%  transactions
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'ProxiedProvider',
    'SingleFile is hooking the IntersectionObserver',
    'CourseTariffByLink was not found'
  ],
  allowUrls: [
    window.location.hostname
  ]
});
